<template>
  <div style="background-color: #ffffff; height: 100%">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave('addForm')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <el-form
          ref="addForm"
          :model="addForm"
          :rules="rules"
          label-width="120px"
        >
          <div class="formTopic">基本信息</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="项目名称" prop="projectName">
                <!-- <el-select
                  v-model="addForm.projectName"
                  placeholder="请选择项目"
                  @change="treeListchange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select> -->
                <avue-input-tree
                  default-expand-all
                  placeholder="请选择项目"
                  :props="defaultProps"
                  v-model="addForm.projectId"
                  :dic="options"
                  @change="treeListchange"
                >
                </avue-input-tree>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目编号" prop="projectCode">
                <el-input
                  v-model="addForm.projectCode"
                  placeholder="请输入项目编号"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排序">
                <el-input
                  v-model="addForm.sort"
                  placeholder="请输入排序"
                  type="number"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="账号" prop="accountNumber">
                <el-input
                  v-model="addForm.accountNumber"
                  placeholder="请输入账号"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="密码" prop="password">
                <el-input v-model="addForm.password" placeholder="请输入密码">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="状态">
                <el-select v-model="addForm.state" placeholder="请选择">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注">
                <el-input
                  v-model="addForm.remark"
                  type="textarea"
                  placeholder="请输入备注"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";

import { getToken } from "@/util/auth";
import { dateFormat } from "@/util/date";
import * as API from "@/api/dbtMonitorAccount/dbtMonitorAccount";
import { mapGetters } from "vuex";
import {
  dbtmonitoraccountSave,
  dbtmonitoraccountDetail,
  dbtmonitoraccountUpdate,
} from "../../api/visualization/index";
import { treePro, treeToProject } from "@/api/dbtComon/dbtComon";
export default {
  data() {
    return {
      formId: "",
      formType: "add",
      readonly: false,
      headTitle: "新增项目监控账号",
      addForm: {
        projectName: "",
         projectId: "",
        projectCode: "",
        accountNumber: "",
        password: "",
        remark: "",
        sort: "",
        createUserName: "",
        state: "启用",
        id: "",
      },

      defaultProps: {
        children: "children",
        label: "title",
      },
      rules: {
        projectId: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
        projectCode: [
          { required: true, message: "请输入项目编号", trigger: "blur" },
        ],
        accountNumber: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      statusOptions: [
        {
          label: "启用",
          value: "启用",
        },
        {
          label: "禁用",
          value: "禁用",
        },
      ],
      options: [],
    };
  },

  computed: {
    headBtnOptions() {
      let result = [];
      result.push({
        label: "保存",
        emit: "head-save",
        type: "button",
        btnOptType: "save",
      });

      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    init() {
      let id = this.$route.query.id;
      let type = this.$route.query.type;
      this.formType = type;
      this.formId = id;

      let pId = this.userInfo.dept_id;
      treeToProject({ parentId: pId }, (data) => {
        console.log("项目列表", data);
        this.options = data;
      });

      if (type == "add") {
        this.headTitle = "新增项目监控账号";
      }
      if (type == "updata") {
        this.headTitle = "编辑项目监控账号";
        this.dbtmonitoraccountDetail();
      }
    },
    treeListchange(val) {
      console.log("选择器", val);
      this.addForm.projectCode = val;
      let obj = this.findById(this.options, val);
      this.addForm.projectName = obj.title;
    },
    dbtmonitoraccountDetail() {
      let that = this;
      let data = {
        id: this.formId,
      };
      dbtmonitoraccountDetail(data).then((res) => {
        const { data } = res.data;
        Object.keys(this.addForm).forEach((key) => {
          this.addForm[key] = data[key];
        });
      });
    },
    headSave(formName) {
      let that = this;
      if (this.formType == "add") {
        this.SaveFunction(formName);
      }
      if (this.formType == "updata") {
        this.UpdateFunction(formName);
      }
    },
    UpdateFunction(formName) {
      let that = this;
      this.addForm.createUserName = this.userInfo.real_name;
      let data = this.addForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          dbtmonitoraccountUpdate(data).then((res) => {
            that.$message({
              message: "操作成功",
              type: "success",
            });
            that.headCancel();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    SaveFunction(formName) {
      let that = this;
      this.addForm.createUserName = this.userInfo.real_name;
      let data = this.addForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          dbtmonitoraccountSave(data).then((res) => {
            that.$message({
              message: "操作成功",
              type: "success",
            });
            that.headCancel();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    findById(items, id) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          return items[i]; // 找到匹配的id，返回该对象
        }
        if (items[i].children && items[i].children.length > 0) {
          let found = this.findById(items[i].children, id); // 递归搜索子数组
          if (found) return found;
        }
      }
      return null; // 如果没有找到匹配的id，返回null
    },
  },
};
</script>

<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.formTopic {
  // margin: 0 12px;
  margin-bottom: 12px;

  // width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.disUoloadSty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }

  ::v-deep .el-upload__tip {
    display: none;
  }

  ::v-deep .el-upload--text {
    display: none;
  }
}

::v-deep.upload-disabled .el-upload--picture-card {
  display: none !important;
}

::v-deep.upload-disabled .el-upload--text {
  display: none !important;
}

::v-deep input[type="number"]::-webkit-outer-spin-button,
::v-deep input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
