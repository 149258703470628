var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#ffffff", height: "100%" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("addForm")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "addForm",
                attrs: {
                  model: _vm.addForm,
                  rules: _vm.rules,
                  "label-width": "120px",
                },
              },
              [
                _c("div", { staticClass: "formTopic" }, [_vm._v("基本信息")]),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "项目名称", prop: "projectName" } },
                          [
                            _c("avue-input-tree", {
                              attrs: {
                                "default-expand-all": "",
                                placeholder: "请选择项目",
                                props: _vm.defaultProps,
                                dic: _vm.options,
                              },
                              on: { change: _vm.treeListchange },
                              model: {
                                value: _vm.addForm.projectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "projectId", $$v)
                                },
                                expression: "addForm.projectId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "项目编号", prop: "projectCode" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入项目编号" },
                              model: {
                                value: _vm.addForm.projectCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "projectCode", $$v)
                                },
                                expression: "addForm.projectCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排序" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入排序",
                                type: "number",
                              },
                              model: {
                                value: _vm.addForm.sort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "sort", $$v)
                                },
                                expression: "addForm.sort",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账号", prop: "accountNumber" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入账号" },
                              model: {
                                value: _vm.addForm.accountNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "accountNumber", $$v)
                                },
                                expression: "addForm.accountNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "密码", prop: "password" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入密码" },
                              model: {
                                value: _vm.addForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "password", $$v)
                                },
                                expression: "addForm.password",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.addForm.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "state", $$v)
                                  },
                                  expression: "addForm.state",
                                },
                              },
                              _vm._l(_vm.statusOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                placeholder: "请输入备注",
                                autosize: { minRows: 2, maxRows: 4 },
                              },
                              model: {
                                value: _vm.addForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "remark", $$v)
                                },
                                expression: "addForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }